import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import LOGO from "../../assets/images/logo.svg";
import Header from "../../base-components/Header";
import axios from "../../axios";
import Loader from "../../base-components/Loader/Loader";
import { FatchApi } from "../../utils/FatchApi";

function Login() {
  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const path = window.location.pathname;
  const lastSlashIndex = path.lastIndexOf("/");
  const slugFromUrl =
    lastSlashIndex !== -1 ? path.substring(lastSlashIndex + 1) : "";

  ////

  const TERMS = "/api/v1/terms-conditions";
  const PRIVACY = '/api/v1/privacy-policy';
  const [termsId, settermsId] = useState("6756d4474fc38390fd130149");
  const [policy, setpolicyId] = useState("6756d5e309850967507e7511");
  const [formdata, setFormdata] = useState();
  const location = useLocation();

  const [loader,setLoader]=useState(false)


  const FetchTerms = async (path) => {
  setLoader(true)   
 
    try {

      const data = await FatchApi({
        // user: User.user,
        request: "get",
        // data: obj,
        Api: `${ path === '/privacy-policy' ? PRIVACY : TERMS}/${ path === '/privacy-policy' ? policy : termsId }`
      });
      if(data){
        console.log(data,'dkkkkk');
        setFormdata(data.body);
        setLoader(false)
      }

  //     const Response = await axios.get(`${ path === '/privacy-policy' ? PRIVACY : TERMS}/${ path === '/privacy-policy' ? policy : termsId }`, {
  //     });
  //     if (Response) {
  //       console.log(Response,'fff');
  //     }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

 

  useEffect(() => {
    FetchTerms(location.pathname);
  }, []);

  ////

  return (
    <div className="container">
      <Header />
      {loader ? (
        <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'90vh'}} >
          <Loader />
        </div>
      ) : (
        <div
          style={{
            width: "80%",
            marginLeft: "10%",
            marginBottom: "20px",
            marginTop: "10px",
            border: "solid 1px transparent",
          }}
        >
          <section
            style={{ marginBottom: "25px" }}
            dangerouslySetInnerHTML={{ __html: formdata }}
          />
        </div>
      )}
    </div>
  );
}

export default Login;
