// BackUp
// export const API_BASE_URL = "http://192.168.8.108:8001"; 
// Shoiab Mobile
// export const API_BASE_URL = "http://192.168.228.165:8001"; 
// Sath Wala 
// export const API_BASE_URL = "http://192.168.1.20:8001"; 
// hamra wala
// export const API_BASE_URL = "http://192.168.1.50:8001"; 

//  shoiab(ngrok)
// export const API_BASE_URL='https://705b-119-155-25-109.ngrok-free.app';

// live
export const API_BASE_URL ='https://langbookserver.langbook.org'

// export const API_BASE_URL ='http://192.168.1.13:8000'

// User Releated Api
export const Registeruser = "/users/register";
export const loginuser = "/users/login";
export const FORGOT_API = "/users/forgot-password";
export const VERIFY_OTP_API = "/users/verify-otp";
export const RESET_PASSWORD_API = "/users/reset-password";
export const PROTECTED = "/users/protected";
export const GET_ALL_LANGUAGES = '/api/admin/languages';

// Langiages APIS

export const GetAllCources = "/api/v1/courses";
