import ReactDOM from "react-dom/client";
import React, { useEffect, useState, useRef } from "react";
import useAuth from "./../../hooks/useAuth";
import { doPost } from "./../../utils/apiCalls";
import { validateEmail } from "../../utils/functions";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Earn from "../../assets/images/earn-money.png";
import Learn from "../../assets/images/learn_img.png";
import HeaderLogin from "../../base-components/Header/HeaderLogin";
import useToast from "../../hooks/useToast";
import Select from "react-select";
import {
  AiFillCheckCircle,
  AiFillClockCircle,
  AiFillPlusCircle,
  AiFillQuestionCircle,
  AiOutlineArrowRight,
} from "react-icons/ai";
import {
  IoCloseCircleOutline,
  IoCopyOutline,
  IoHeartOutline,
  IoHeart,
  IoFlagOutline,
  IoFlag,
} from "react-icons/io5";
import {
  FaHandPointRight,
  FaRegClock,
  FaRegCirclePlay,
  FaRegCirclePause,
  FaTrash,
} from "react-icons/fa6";
import { IoMdAttach } from "react-icons/io";

import { BiMessage } from "react-icons/bi";

import { LuClipboardList, LuRepeat } from "react-icons/lu";
import Source from "./../../assets/source.mp3";
import Target from "./../../assets/target.mp3";
import { Tooltip } from "react-tooltip";
import { FatchApi } from "../../utils/FatchApi";

import { UploadAssests } from "../../utils/Aws_Upload";
import { useDispatch, useSelector } from "react-redux";
import { GetlanguageRequest } from "../../lib/Redux/actions";
import { GET_ALL_LANGUAGES } from "../../constants/apiEndPoints";
import Loader from "../../base-components/Loader/Loader";
import { clearUser } from "../../lib/Redux/slices/userslice";

import useResetReducer from "../../../src/utils/useResetReducer";

function OnBoard() {
  const [Index, setIndex] = useState();
  const resetAllReducers = useResetReducer();

  const location = useLocation();

  const routdata = location.state;

  const [updateLoading, setUpdateLoading] = useState(false);

  // console.log(routdata.email,'routedata');

  const Languages = useSelector((state) => state.languages);
  const IsDialectCondition = useSelector((state) => state.IsDialect);
  const { user } = useSelector((state) => state.user);
  const User = useSelector((state) => state.user);
  const [selectedOptoin, setSelectedOptions] = useState([]);
  const [selectedOptoin2, setSelectedOptions2] = useState([]);

  // console.log(user.profileVerified,'sdsd');

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      dispatch(
        GetlanguageRequest({
          // user: user,
          request: "get",
          data: "",
          Api: GET_ALL_LANGUAGES,
        })
      );
    };
    fetchData();
    // window.location.reload();
  }, []);

  useEffect(() => {
    if (!User.isAuthenticated) {
      navigate("/login");
    }
  }, []);

  const [dia, setDia] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const data = await FatchApi({
        request: "get",
        data: "",
        Api: `/api/v1/dialectics`,
      });
      //  console.log(Languages.data.languages,'lllllll');
      // console.log(data.languages, "ddddsss");
      setDia(data && data?.languages);
    };

    fetchData();
  }, []);

  const showToast = useToast();

  const navigate = useNavigate();
  const { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin } =
    useAuth();
  const [heading, setHeading] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [popup, setPopup] = useState(true);
  const [spaces, setSpaces] = useState(true);
  const [play, setPlay] = useState(false);
  const [dictionary, setDictionary] = useState(false);
  const [selected, setSelected] = useState([]);
  const [audioCount, setAudioCount] = useState(0);
  const [audio, setAudio] = useState(null);
  const [isPlaying, setPlaying] = useState(false);
  const [countdown, setCountdown] = useState(false);
  const [count, setCount] = useState(5);
  const [heart, setHeart] = useState(false);
  const [flag, setFlag] = useState(false);
  const [Pdata, setPdata] = useState([]);

  const urldictionary = "https://glosbe.com/en/es/";
  const urldictionarytarget = "https://glosbe.com/es/en/";

  const [variData, setVariData] = useState(null);

  const do_save_langs = async (val) => {
    const isValueInArray = selected.includes(val);
    if (isValueInArray) {
      const newArray = selected.filter((item) => item !== val);
      setSelected(newArray);
    } else {
      setSelected([...selected, val]);
    }
  };

  const sentence = [
    { title: "Do", type: "verbs" },
    { title: "you", type: "pronouns" },
    { title: "see", type: "verbs" },
    { title: "the", type: "articles" },
    { title: "men", type: "nouns" },
    { title: "over", type: "prepositions" },
    { title: "there", type: "adverb" },
  ];

  const actual_sentence = "Siehst du die Männer dort drüben";
  const sentence_target = [
    { title: "Siehst", type: "verbs" },
    { title: "du", type: "pronouns" },
    { title: "die", type: "verbs" },
    { title: "Männer", type: "nouns" },
    { title: "dort", type: "adverb" },
    { title: "drüben", type: "adverb" },
  ];

  const reasonsflag = [
    { title: "mistake in the source language audio", id: 1 },
    { title: "mistake in the target language audio", id: 2 },
    { title: "mistake in the source language", id: 3 },
    { title: "mistake in the target language", id: 4 },
    { title: "words  marked incorrectly", id: 5 },
    { title: "wrong tag (classic, slang, insult)", id: 6 },
    { title: "inappropriate sentence", id: 7 },
    { title: "other", id: 8 },
  ];

  const playAudioSource = () => {
    const newAudio = new Audio(Source);
    newAudio.play();
    setTimeout(() => {
      const newAudio = new Audio(Target);
      newAudio.play();
      setAudio(newAudio);
      newAudio.addEventListener("ended", handleAudioEnded);
    }, 3000);
  };
  const handleAudioEnded = () => {
    setPlaying(false);
    setPlay(false);
    setCountdown(true);
  };
  const pauseAudio = () => {
    if (audio) {
      audio.pause();
    }
  };

  const [BeforeSub, setBeforSub] = useState();

  const handleGet = async () => {
    const data = await FatchApi({
      request: "get",
      data: "",
      Api: `/api/v1/admins/get-earner-verification/${user?.userEmail}`,
    });

    console.log(data, "check it");
    setPdata(data);

    if (data?.message === "data get successfully") {
      setVariData(data?.earnerVerification);
      setNativeEasy(data?.earnerVerification?.nativeEssay);
      setNative(data?.earnerVerification?.nativeLanguage);
      setSelectedFile1(data?.earnerVerification?.nativeAudio);
      setSelectedFile2(data?.earnerVerification?.teachAudio);
      setDialectAudio(data?.earnerVerification.teachEssay);
      setSelectedOptionsC1(data?.earnerVerification?.teachLanguageC1);
      setSelectedOptionsC2(data && data?.earnerVerification?.teachLanguageC2);
      setSelectedDialects(data?.earnerVerification?.teachDialect);
      // console.log(data?.teachLanguageC1?.length, "llleenn");
      if (data?.earnerVerification.teachLanguageC1?.length >= 2) {
        const slicedata = data?.earnerVerification?.teachLanguageC1.slice(
          0,
          -1
        );

        setLanguages(slicedata);
      }
      if (data?.earnerVerification?.teachLanguageC2?.length >= 2) {
        const slicedata = data?.earnerVerification?.teachLanguageC1.slice(
          0,
          -1
        );

        setLanguagesC2(slicedata);
      }

      console.log(data, "vari data...");
      setBeforSub(false);
    } else {
      setBeforSub(false);
      setMessage(data?.message);
    }
  };

  useEffect(() => {
    handleGet();
  }, []);

  useEffect(() => {
    if (countdown) {
      const intervalId = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
      if (count === 0) {
        clearInterval(intervalId);
        window.location.reload();
      }
      return () => clearInterval(intervalId);
    }
  }, [count, countdown]);

  const copyText = async (val) => {
    navigator.clipboard.writeText(val);
    toast.success("Target sentence copied successfully!");
  };

  const [languages, setLanguages] = useState([]);
  const [languagesC2, setLanguagesC2] = useState([]);

  const addLanguage = () => {
    const newLanguage = { id: Date.now(), value: "" };
    setLanguages([...languages, newLanguage]);
    console.log(languages, "languages...");
  };

  const deleteLanguage = (id, name) => {
    const updatedLanguages = languages.filter((language) => language.id !== id);
    setLanguages(updatedLanguages);

    const afterdel = selectedOptoin.filter((items) => {
      return items?.c1 !== name;
    });

    const slicedata = afterdel?.slice(0, -1);
    setSelectedOptions(afterdel);
    setLanguages(slicedata);
  };

  // useEffect(()=>{
  //   if(languages.length >0){
  //     deleteLanguage();
  //   }
  // },[selectedOptoin])

  // useEffect

  const handleLanguageChange = (id, event) => {
    const updatedLanguages = languages.map((language) => {
      if (language.id === id) {
        return { ...language, value: event.target.value };
      }
      return language;
    });
    setLanguages(updatedLanguages);
  };

  // C2 languages

  // const setNewOptions = (options2) => {
  //   setSelectedOptions2(options2, () => {
  //     // Perform any action that depends on the updated state here
  //     console.log('State has been updated:', options2);
  //   });
  // };
  useEffect(() => {
    // console.log(selectedOptoin2, "selectedOption2................");
  }, [selectedOptoin2]); // This effect will run every time selectedOption2 changes

  const addLanguageC2 = (name) => {
    console.log(selectedOptoin2, "optoin2");

    // const slicedata = selectedOptoin2.slice(1,-1)

    const slicedata = selectedOptoin2.filter((items) => {
      return items.c1 !== name;
    });

    console.log(slicedata?.length, "spliearr");

    // New data to be added

    console.log(languagesC2, "lll");

    const newdata = { c1: "selected option", index: selectedOptoin2.length };

    // Create a new array with the sliced data and new data
    const newArray = [...slicedata, newdata];

    console.log(newArray, "newarr");
    const newSelectedOption = [...selectedOptoin2, newdata];
    //  debugger
    //  console.log(newSelectedOption, 'newSelectedOption');

    // Update the state with the new array
    setLanguagesC2(newArray);
    setSelectedOptions2(newSelectedOption);

    console.log(selectedOptoin2, "selectedOptoin2");
  };

  const deleteLanguageC2 = (id, name) => {
    const updatedLanguages = languagesC2.filter(
      (language) => language.id !== id
    );
    setLanguagesC2(updatedLanguages);

    const afterdel = selectedOptoin2.filter((items) => {
      return items?.c1 !== name;
    });

    const slicedata = afterdel?.slice(0, -1);
    setSelectedOptions2(afterdel);
    setLanguagesC2(slicedata);
  };

  // const deleteLanguage = (id,name) => {
  //   const updatedLanguages = languages.filter((language) => language.id !== id);
  //   setLanguages(updatedLanguages);

  //   const afterdel = selectedOptoin.filter((items)=>{
  //     return items?.c1 !== name
  //   })

  //   const slicedata = afterdel?.slice(0,-1)
  //   setSelectedOptions(afterdel);
  //   setLanguages(slicedata)
  // };

  const handleLanguageChangeC2 = (id, event) => {
    const updatedLanguages = languagesC2.map((language) => {
      if (language.id === id) {
        return { ...language, value: event.target.value };
      }
      return language;
    });
    setLanguagesC2(updatedLanguages);
  };

  const InputRef1 = useRef();
  const InputRef2 = useRef();

  const [native, setNative] = useState(null);
  // const [nativeEasy, setNativeEasy] = useState();
  const [selectedfile1, setSelectedFile1] = useState(null);
  const [selectedfile2, setSelectedFile2] = useState(null);
  const [Message, setMessage] = useState(null);

  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [audio1loader, setAudio1loader] = useState(false);
  const [audio2loader, setAudio2loader] = useState(false);

  const handleFileChange = async (event, type) => {
    if (type === "native") {
      if (
        event?.target?.files[0] &&
        event?.target?.files[0] != null &&
        event?.target?.files[0] != undefined
      ) {
        setAudio1loader(true);
        const nativeAudio = await UploadAssests(event?.target?.files[0]);
        if (nativeAudio) {
          setSelectedFile1(nativeAudio);
          setShow1(true);
          setAudio1loader(false);
        }
      }
    } else {
      if (
        event?.target?.files[0] &&
        event?.target?.files[0] != null &&
        event?.target?.files[0] != undefined
      ) {
        setAudio2loader(true);
        const nativeAudio = await UploadAssests(event?.target?.files[0]);
        if (nativeAudio) {
          setSelectedFile2(nativeAudio);
          setShow2(true);
          setAudio2loader(false);
        }
      }
    }

    // console.log(selectedfile1, "its one ", selectedfile2, "its two");
  };

  const handleUpload = (type) => {
    if (type === "native") {
      InputRef1?.current.click();
    } else if (type === "notnative") {
      InputRef2?.current.click();
    }
  };

  const [NativeLan, setNativeLan] = useState();

  const handleNative = (selectedOption) => {
    if (!selectedOption) {
      setNative(null);
      return;
    }

    const selectedLanguageName = selectedOption.value;
    const data = Languages?.data?.languages?.filter(
      (item) => item.name === selectedLanguageName
    );

    // console.log(data,'native data..');

    if (data.length > 0) {
      setNative(data[0]);
    } else {
      setNative(null); // Handle case where no matching language is found
    }
  };

  const [DialectStrip, setDialectStrip] = useState(null);

  const handleSubmit = async () => {
    if (audio2loader || audio1loader) {
      return;
    }

    if (native == undefined || native == "" || native == null) {
      showToast("Native Language is required", "error");
      return;
    }

    if (nativeEasy == undefined || nativeEasy == "" || nativeEasy == null) {
      showToast("Native Essay is required", "error");
      return false;
    }

    if (
      selectedfile1 == undefined ||
      selectedfile1 == "" ||
      selectedfile1 == null
    ) {
      showToast("Native audio is required", "error");
      return false;
    }

    if (selectedOptionsC1?.length < 1) {
      showToast("C1 Language is required", "error");
      return;
    } else {
      const data = selectedOptionsC1;
      const fil = data?.filter((item) => item?.label === native?.name);
      if (fil && fil?.length >= 1) {
      } else {
        const data2 = data?.push({ value: native?.name, label: native?.name });
        setSelectedOptionsC1(data2);
      }
    }

    if (selectedDialects === null || selectedDialects?.length < 1) {
    } else {
      if (selectedDialects !== null || selectedDialects?.length >= 1) {
        if (
          dialectAudio == undefined ||
          dialectAudio == "" ||
          dialectAudio == null
        ) {
          showToast("Dialect Essay is required", "error");
          return false;
        }

        if (
          selectedfile2 == undefined ||
          selectedfile2 == "" ||
          selectedfile2 == null
        ) {
          showToast("Dialect audio is required", "error");
          return false;
        }
      }
    }

    
    
    if(selectedDialects?.label == 'other'){
      console.log(otherValue ,'lable......');
      if(otherValue == undefined || otherValue == null || otherValue?.length <= 0){
        showToast("Dialect name is required", "error");
        return
      }
    }

    var obj = {
      nativeLanguage: native,
      nativeEssay: nativeEasy,
      nativeAudio: selectedfile1,
      teachLanguageC1: selectedOptionsC1,
      teachLanguageC2: selectedOptionsC2,
      teachDialect:
        selectedDialects?.label == "other"
          ? { label: otherValue, value: otherValue }
          : selectedDialects,
      teachEssay: dialectAudio,
      teachAudio: selectedfile2,
      email: user?.userEmail,
      otherDialect: selectedDialects?.label == "other" ? true : false,
      isVerificationRequested: true,
    };

    var data;

    data = await FatchApi({
      request: "post",
      data: obj,
      Api: `/users/create-earner-verification`,
    });

    if (data) {
      localStorage.removeItem("target");
      localStorage.removeItem("source");

      handleGet();

      showToast(
        "Your verification request has been submitted successfully",
        "success"
      );
      setBeforSub(true);
      setMessage(data?.message);
    }
  };

  const [nativeEasy, setNativeEasy] = useState("");
  const [dialectAudio, setDialectAudio] = useState();
  const [wordCount, setWordCount] = useState(0);
  const maxWordsNative = 50;
  const maxWordsAudio = 50;

  const handleTextChange = (e) => {
    const text = e.target.value;
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    if (words.length <= maxWordsNative) {
      setNativeEasy(text);
      setWordCount(words.length);
    }
  };

  const handleTextChange2 = (e) => {
    const text = e.target.value;
    const words = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0);

    if (words.length <= maxWordsAudio) {
      setDialectAudio(text);
      setWordCount(words.length);
    }
  };

  ////////////////////////////////////

  function checkTeachLanguages(varidata, selectedOptoinC2) {
    const check = [];
    const selectedValuesSet = new Set(
      varidata?.teachLanguageC2?.map((item) => item?.value)
    );
    selectedOptoinC2?.forEach((language) => {
      if (selectedValuesSet?.has(language.value)) {
        check?.push(true);
      } else {
        check?.push(false);
      }
    });

    return check;
  }

  ///////////////////////////////////

  const handleUpdate = async () => {
    setUpdateLoading(true);

    var obj = {
      nativeLanguage: native,
      nativeEssay: nativeEasy,
      nativeAudio: selectedfile1,
      teachLanguageC1: selectedOptionsC1,
      teachLanguageC2: selectedOptionsC2,
      // teachDialect: "New York",
      teachEssay: dialectAudio,
      teachAudio: selectedfile2,
      email: user?.userEmail,
    };

    var data;

    ///

    const result = checkTeachLanguages(variData, selectedOptionsC2);
    if (result?.some((item) => item === false)) {

      const confirmRemove = window.confirm(
      "You will be temporarily un-verified after confirming. Pending new C2 language verification by admin."
    );

    if (!confirmRemove) {
      setUpdateLoading(false);
        return
      }
    }

    ///

    try {
      data = await FatchApi({
        request: "put",
        data: obj,
        Api: `/users/update-earner-verification`,
      });

      if (data) {
        localStorage.removeItem("target");
        localStorage.removeItem("source");
        const result = checkTeachLanguages(variData, selectedOptionsC2);
        if (result?.some((item) => item === false)) {
          showToast(
            "You have been temporarily unverified",
            "info"
          );
        }
        setUpdateLoading(false);
        showToast(data?.message, "success");
        handleGet();
      }
    } catch (err) {
      setUpdateLoading(false);
      showToast(data?.message, "error");
    }

    if (selectedOptionsC2?.includes(variData?.teachLanguageC2)) {
      showToast("Temporary Un Verified", "info");
    }
  };

  ////////////////////////////////

  const isOptionDisabled = (languageName) => {
    // debugger
    //  const d= ;
    const boolean = arry?.some((option) => option.c1 === languageName);
    return boolean;
    //  console.log(boolean,'dddd');
  };

  const isOptionDisabled2 = (languageName) => {
    // debugger
    //  const d= ;
    const boolean = arry2?.some((option) => option.c1 === languageName);
    return boolean;
    console.log(boolean, "dddd");
  };

  ////////////////////////
  ////////////////////////

  const [arry, setArry] = useState([{ c1: "selected option", id: "qty123" }]);

  const [arry2, setArry2] = useState([{ c1: "selected option", id: "qty123" }]);

  const handAdd = () => {
    // Generate a random number between 0 and 100
    const randomId = Math.random() * 100;

    // Create a new object with the random number as ID
    const obj = { c1: "selected optoin", id: randomId.toString() };

    console.log(obj, "obj");

    // Update the state with the new object
    setArry([...arry, obj]);

    console.log(arry, "Updated array");
  };

  const handAdd2 = () => {
    // Generate a random number between 0 and 100
    const randomId = Math.random() * 100;

    // Create a new object with the random number as ID
    const obj = { c1: "Selected Option", id: randomId.toString() };

    console.log(obj, "obj");

    // Update the state with the new object
    setArry2([...arry2, obj]);

    console.log(arry2, "Updated array");
  };

  const handleDel = (id) => {
    console.log(id, "iidd");

    const fill = arry?.filter((items) => {
      return items.id !== id;
    });

    console.log(fill, "filll");

    setArry(fill);
  };

  const handleDel2 = (id) => {
    console.log(id, "iidd");

    const fill = arry2?.filter((items) => {
      return items.id !== id;
    });

    console.log(fill, "filll");

    setArry2(fill);
  };

  const handleC1 = (id, e) => {
    const duplicate = [...arry];
    const entity = duplicate.find((item) => item.id === id);
    if (entity) {
      const index = duplicate.findIndex((item) => item.id === id);
      duplicate[index].c1 = e.target.value;
      setArry(duplicate);

      console.log(arry, "arry");
    }
  };

  const handleC2 = (id, e) => {
    const duplicate = [...arry2];
    const entity = duplicate.find((item) => item.id === id);
    if (entity) {
      const index = duplicate.findIndex((item) => item.id === id);
      duplicate[index].c1 = e.target.value;
      setArry2(duplicate);

      console.log(arry2, "arry");
    }
  };

  const [otherValue, setOhterValue] = useState();
  const [other, setOther] = useState(false);
  const [otherloading, setOtherloading] = useState(false);

  const handleNewDialectSubmit = async () => {
    if (other) {
      setOtherloading(true);

      var obj = { dialectName: otherValue };

      const data = await FatchApi({
        request: "post",
        data: obj,
        Api: `/api/v1/dialect-request`,
      });

      if (data) {
        setOtherloading(false);
      }
    }
  };

  //////////

  const formattedLanguages = Languages?.data?.languages
    ?.slice() // Create a shallow copy of the array to avoid mutating the original data
    ?.sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically by language name
    ?.map((language) => ({
      value: language.name,
      label: language.name,
    }));

  ////////

  const handleDialect = (val) => {
    const fildata = dia?.filter((items) => {
      return items.name === val;
    });

    console.log(fildata, "fildata");

    setDialectStrip(fildata);

    console.log(DialectStrip);
  };

  /////

  const [selectedOptionsC1, setSelectedOptionsC1] = useState([]);
  const [selectedOptionsC2, setSelectedOptionsC2] = useState([]);
  const [selectedDialects, setSelectedDialects] = useState(null);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
    }),
  };

  const handleChangeC1 = (selected) => {
    setSelectedOptionsC1(selected);
  };

  const handleChangeC2 = (selected) => {
    setSelectedOptionsC2(selected);
  };

  const handleChangeDialects = (selected) => {
    setSelectedDialects(selected);
  };

  const [filtedia, setFiltedia] = useState([]);

  useEffect(() => {
    // console.log(native, dia, "native....");

    const filtered =
      dia?.filter((items) => {
        return items?.code?.name === native?.name;
      }) || [];

    setFiltedia(filtered);
    console.log(filtered, "filtedia....");
  }, [native, dia]);

  const DialectsArray = [
    ...(filtedia
      ?.slice()
      ?.sort((a, b) => a?.name?.localeCompare(b?.name))
      ?.map((dialect) => ({
        value: dialect.name,
        label: dialect.name,
      })) ?? []),
    { value: "other", label: "other" },
  ];

  /////

  const varificaitonCompnent = (
    <div className="wrapper">
      <div className="my_languages_wrap">
        <div className="verifiy_form w60">
          <div className="card wd100 p20 ">
            <div className="form-group">
              <label>
                <span>Your native language *</span>
                <AiFillQuestionCircle
                  data-tooltip-id="question_new_sentence"
                  data-tooltip-html={
                    "You can only teach one native language. If you have more than one,  you can pick which one you want to teach. You cannot change this later."
                  }
                />
                <Tooltip
                  id="question_new_sentence"
                  style={{
                    textAlign: "left",
                    padding: "13px 10px",
                    fontSize: "13px",
                    width: "300px",
                    zIndex: 9,
                  }}
                />
              </label>
              {
                <Select
                  isDisabled={variData ? true : false}
                  onChange={handleNative}
                  className="basic-single"
                  classNamePrefix="select"
                  name="language"
                  options={formattedLanguages}
                  isSearchable={true}
                  placeholder={
                    variData
                      ? native?.name
                      : Languages?.loading
                      ? "Loading languages..."
                      : "Select your Native Language"
                  }
                />
              }
            </div>

            {native ? (
              <div className="form-group">
                <label>
                  Please write a 50 word essay in your native language about any
                  topic and provide audio. *
                </label>

                <textarea
                  disabled={variData ? true : false}
                  onChange={handleTextChange}
                  value={nativeEasy}
                  className=""
                  placeholder="Enter here..."
                ></textarea>
                {wordCount > 30 && (
                  <p
                    style={{
                      transform: "translateY(-5px)",
                      color: `${maxWordsNative - wordCount < 10 ? "red" : ""}`,
                    }}
                  >
                    {maxWordsNative - wordCount} words left
                  </p>
                )}
              </div>
            ) : (
              ""
            )}

            {/* /// */}

            <div className="form-group">
              {native ? (
                <>
                  <label>Please provide audio *</label>

                  <input
                    disabled={audio1loader ? true : false}
                    onChange={(e) => handleFileChange(e, "native")}
                    ref={InputRef1}
                    type="file"
                    style={{ display: "none" }}
                  />

                  {variData ? (
                    ""
                  ) : (
                    <div
                      className="upload_box"
                      onClick={() => handleUpload("native")}
                    >
                      <IoMdAttach size={25} />
                      <span>{` ${
                        audio1loader ? "Uploading..." : "Upload Audio here"
                      } `}</span>
                    </div>
                  )}

                  {selectedfile1 && (
                    <audio controls>
                      <source src={selectedfile1} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                </>
              ) : (
                ""
              )}

              <div
                className="form-group text-center"
                style={{
                  borderBottom: "1px solid #f0f0f0",
                  paddingBottom: "20px",
                }}
              ></div>

              <div className="form-group">
                <label
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems:'flex-start'
                  }}
                >
                  <div >
                    Which languages do you know well enough (C1 or better) to
                    teach your first language from? *
                  </div>
                  {/* ///////////////// */}
                  <Select
                    isMulti
                    onChange={handleChangeC1}
                    className="basic-single"
                    classNamePrefix="select"
                    name="language"
                    options={formattedLanguages}
                    isSearchable={true}
                    placeholder="Enter C1 Language"
                    value={selectedOptionsC1}
                    styles={customStyles}
                  />

                  {/* /////////////// */}
                </label>
              </div>
              <div className="form-group">
                <label style={{ display: "flex", flexDirection: "column" }}>
                  <span>
                    Do you have C2 level command of any language that has very
                    few or no native speakers? E.g. Latin, Esperanto, Cornish,
                    etc
                  </span>
                  <Select
                    isMulti
                    onChange={handleChangeC2}
                    className="basic-single"
                    classNamePrefix="select"
                    name="language"
                    options={formattedLanguages}
                    isSearchable={true}
                    placeholder="Enter C2 Language"
                    value={selectedOptionsC2}
                    styles={customStyles}
                  />

                  {/* ////// */}
                </label>
              </div>
              <div
                className="form-group text-center"
                style={{
                  borderBottom: "1px solid #f0f0f0",
                  paddingBottom: "20px",
                }}
              >
                <p>
                  Please contact us at
                  <a href="mailto:support@langbook.org">
                    {" "}
                    support@langbook.org
                  </a>
                  <br />to verify your skills in those languages.
                </p>
              </div>

              <div className="form-group">
                <label>
                  <span>Do you speak a dialect well enough to teach it?</span>
                </label>

                <Select
                  isDisabled={
                    variData ? true : false || native === null ? true : false
                  }
                  onChange={handleChangeDialects}
                  className="basic-single"
                  classNamePrefix="select"
                  name="language"
                  options={DialectsArray}
                  isSearchable={true}
                  placeholder={
                    variData ? selectedDialects?.label : "Enter Dialect"
                  }
                  styles={customStyles}
                  value={selectedDialects}
                />

                {/* /////////////// */}

                {selectedDialects?.label == "other" && (
                  <input
                    disabled={variData ? true : false}
                    onChange={(e) => setOhterValue(e.target.value)} // Assuming setOhterValue is your state setter
                    // value={selectedDialects.label}
                    placeholder={
                      variData
                        ? selectedDialects?.label
                        : "Type the name of the dialect here"
                    }
                    style={{
                      cursor: variData ? "default" : "pointer", // Set cursor style based on variData
                      width: "100%",
                      border: "solid 1px gray",
                      paddingLeft: "20px",
                      borderRadius: "4px",
                      height: "30px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                )}
              </div>

              {/* /// */}

              {selectedDialects ? (
                <div className="form-group">
                  <label>
                    <span>
                      Please write a 50 word essay in your dialect about any
                      topic and provide audio.
                    </span>
                    {/* <AiFillQuestionCircle
                      data-tooltip-id="other_question"
                      data-tooltip-html={
                        "Processing your application to contribute your dialect may take some time, as finding a qualified person to review your essay can be challenging. Once you are approved to teach your native language you will be able to contribute to the language courses. You will not have to wait until you are approved to do dialects as well."
                      }
                    /> */}
                  </label>
                  <Tooltip
                    id="other_question"
                    style={{
                      textAlign: "left",
                      padding: "13px 10px",
                      fontSize: "13px",
                      width: "350px",
                      zIndex: 9,
                    }}
                  />
                  <textarea
                    disabled={variData ? true : false}
                    onChange={handleTextChange2}
                    value={dialectAudio}
                    className=""
                    placeholder="Enter here..."
                  ></textarea>
                  {wordCount > 30 && (
                    <p
                      style={{
                        transform: "translateY(-5px)",
                        color: `${maxWordsAudio - wordCount < 10 ? "red" : ""}`,
                      }}
                    >
                      {maxWordsAudio - wordCount} words left
                    </p>
                  )}
                </div>
              ) : (
                ""
              )}

              {selectedDialects ? (
                <>
                  <input
                    disabled={audio2loader}
                    onChange={(e) => handleFileChange(e, "notnative")}
                    ref={InputRef2}
                    type="file"
                    style={{ display: "none" }}
                  />
                  {variData ? (
                    ""
                  ) : (
                    <div
                      style={{ position: "relative" }}
                      className="upload_box"
                      onClick={() => handleUpload("notnative")}
                    >
                      <IoMdAttach size={25} />
                      <span>{` ${
                        audio2loader ? "Uploading..." : "Upload Audio here"
                      } `}</span>
                    </div>
                  )}
                  {selectedfile2 && (
                    <audio controls>
                      <source src={selectedfile2} type="audio/mpeg" />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                </>
              ) : (
                ""
              )}

              <div className="text-center flex-center mt-10px">
                <button
                  onClick={variData ? handleUpdate : handleSubmit}
                  className="button_custom_round primary flex-space-between"
                >
                  {variData ? updateLoading ? <Loader /> : "Update" : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container">
      <HeaderLogin />

      {/* varificaitonCompnent */}
      {/* Pdata?.earnerVerification?.userId?.profileVerified */}

      {Pdata && Pdata?.message == "Earner details not found" ? (
        varificaitonCompnent
      ) : Pdata?.earnerVerification?.userId?.profileVerified ? (
        varificaitonCompnent
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "75vh",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <h1>{Message}</h1> */}
            <h1>Your request has been submitted</h1>
            <p style={{ fontSize: "14px" }}>
              You will be notified once your request has been approved
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default OnBoard;
