import { takeLatest, call, put } from 'redux-saga/effects';
import { GetlanguageRequest,GetlanguageFailure,} from '../../Redux/actions';
import { GetlanguageFailerReducer,GetlanguageSuccessReducer,GetlanguageRequestReducer } from '../../Redux/slices/languagesSlices/languages';
import { FatchApi } from '../../../utils/FatchApi';
import { GET_ALL_LANGUAGES } from '../../../constants/apiEndPoints';

function* fetchDataSaga(action) {
  try {
    yield put(GetlanguageRequestReducer())
    const data = yield call(FatchApi,action.payload);
    if(data){
      yield put(GetlanguageSuccessReducer(data));
    }
  } catch (error) {
    yield put(GetlanguageFailerReducer(error));
  }
}

export default function* GetLanguages() {
  yield takeLatest(GetlanguageRequest().type, fetchDataSaga);
}
